import React from "react";
import Section from "./section";
import styled from "@emotion/styled";

const ColorSection = styled(Section)(({ color, background }) => `
    color: ${color};
    background: ${background};
`);

const Padding = styled.div`
    padding: 2em 5em 0 5em;
    margin: 0 auto;
    max-width: ${({padding}) => padding};
    min-height: 100%;
`;

export default ({ color, background, children, padding = '1500px', ...props }) => (
    <ColorSection color={color} background={background} {...props}>
        <Padding padding={padding}>{children}</Padding>
    </ColorSection>
)